if (module.hot) {
	module.hot.accept();
}

function toggleMenuButton(e) {
	const menuButton = document.getElementById("menu-button");
	const mobileMenu = document.getElementById("mobile-menu");
	menuButton.addEventListener("click", function (e) {
		mobileMenu.classList.toggle("hidden");
	});
	closeMobileMenuOnClick(mobileMenu);
}

function closeMobileMenuOnClick(mobileMenu) {
	mobileMenu.addEventListener("click", function () {
		mobileMenu.classList.toggle("hidden");
	});
}

function setCurrentYear() {
	document.getElementById("current-year").textContent = new Date().getFullYear();
}
function init() {
	setCurrentYear();
	toggleMenuButton();
}

init();